// 话题类型
export const gambit_questionCategorydata = [{
		value: 1,
		name: '趣味测试'
	}, {
		value: 2,
		name: '爱情测试'
	},
	{
		value: 3,
		name: '缘分测试'
	},
	{
		value: 4,
		name: '性格测试'
	}
]

// 答案类型
export const gambit_answerTypeenu = {
	'1': {
		name: '范围题',
	},
	'2': {
		name: '比分题'
	},
	'3': {
		name: '百分比'
	},
	'4': {
		name: '程度占比'
	},
	'5': {
		name: '其他通用'
	}

}

// 答案类型 选择的
export const gambit_answerTypeselect = [{
		value: 1,
		name: '范围题'
	}, {
		value: 2,
		name: '比分题'
	},
	{
		value: 3,
		name: '百分比'
	},
	{
		value: 4,
		name: '程度占比'
	},
	{
		value: 5,
		name: '其他通用'
	}
]





// 题目的
export const gambit_questionTypeselect = [{
		value: 1,
		name: '跳转题'
	}, {
		value: 2,
		name: '得分题'
	},
	{
		value: 3,
		name: '范围题'
	},
	{
		value: 4,
		name: '单个题目'
	},
]



// 话题状态
export const gambit_statusselect = [{
		value: 0,
		name: '下架'
	}, {
		value: 1,
		name: '上架'
	},
	{
		value: 2,
		name: '审核'
	}
]

// 运营方式
export const gambit_free_typeselect = [{
		value: 0,
		name: '免费'
	}, {
		value: 1,
		name: '视频广告'
	},
	{
		value: 2,
		name: '插屏广告'
	},
	// {
	// 	value: 3,
	// 	name: '金钱(暂无)'
	// }
]




// 话题是怎么样的形式查看到具体的内容
// export const gambit_statusselect = [{
// 		value: 0,
// 		name: '下架'
// 	}, {
// 		value: 1,
// 		name: '上架'
// 	},
// 	{
// 		value: 2,
// 		name: '审核'
// 	}
// ]