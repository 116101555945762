<template>
	<div class="wrap">
		<fileinterspace :maxfilenum="1" v-if="showfilevisible" @selectfileArr="selectfileArrfun"
			:showfilevisible="showfilevisible" @closedialog="ceshifilekong"></fileinterspace>
		<el-form ref="queryForm" :model="queryParams" size="small" tooltip-effect="dark" :inline="true">
			<el-form-item label="标题:">
				<el-input v-model="queryParams.gambit_title" clearable style="width: 180px" />
			</el-form-item>
			<!-- 			<el-form-item label="状态">
				<el-select v-model="queryParams.start" style="width: 180px" clearable>
					<el-option v-for="item in options" :key="item.id" :label="item.name" :value="item.id" />
				</el-select>
			</el-form-item> -->
			<el-form-item>
				<el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">查询</el-button>
				<el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
			</el-form-item>
		</el-form>
		<!-- 新增按钮 -->
		<el-row :gutter="10">
			<el-col :span="1.5">
				<el-button type="primary" plain icon="el-icon-plus" size="mini" @click="handleAdd">新增</el-button>
			</el-col>
			<!-- 			<el-col :span="1.5">
				<el-button type="danger" plain icon="el-icon-delete" size="mini" @click="deletedata">删除</el-button>
			</el-col> -->

			<!-- <el-col :span="1.5">
				<el-button type="danger" plain icon="el-icon-delete" size="mini" @click="handleAdd">清空</el-button>
			</el-col> -->
			<!--<el-col :span="1.5">
				<el-button type="warning" plain icon="el-icon-download" size="mini" @click="countDowndata">导出
				</el-button>
			</el-col> -->
			<el-col :span="1.5">
				<el-button type="warning" plain icon="el-icon-refresh-left" size="mini" @click="handshua">刷新</el-button>
			</el-col>
		</el-row>
		<!-- 表格信息 -->
		<el-table v-loading="loading" :lazy="true" :data="tableData" style="width: 100%"
			:header-cell-style="{textAlign: 'center'}" :cell-style="{textAlign: 'center'}"
			@selection-change="handleSelectionChange">
			<!-- <el-table-column type="selection" width="55"></el-table-column> -->
			<el-table-column prop="id" sortable label="id" />
			<el-table-column label="封面">
				<template slot-scope="scope">
					<!-- <el-avatar  shape="square" :size="50" :src="scope.row.cover"></el-avatar> -->
					<el-image :preview-src-list="[scope.row.gambit_coverImgUrl]"
						style="width: 70px; height: 70px;border-radius: 15px;" :src="scope.row.gambit_coverImgUrl"
						fit="contain">
					</el-image>
				</template>
			</el-table-column>
			<el-table-column prop="gambit_title" width="200" label="标题" />
			<el-table-column label="类型">
				<template slot-scope="scope">
					<div>{{gambit_questionCategoryfun(scope.row.gambit_questionCategory).name}} </div>
				</template>
			</el-table-column>
			<el-table-column prop="gambit_subText" width="300" label="描述" />
			<el-table-column prop="createdAt" width="200" label="创建时间" />
			<el-table-column label="操作" width="150" fixed="right">
				<template slot-scope="scope">
					<el-button size="mini" type="success" @click="handleMember(scope.row)">修改</el-button>
					<!-- <el-button v-if="ishowbutton" size="mini" type="success" @click="tochild(scope.row)">子视频</el-button> -->
					<!--<el-button size="mini" type="warning" @click="startUsingOrFreeze(scope.row)">
						{{scope.row.identitystart == 1 ? '冻结' : '正常' }}
					</el-button> -->
					<el-popconfirm style="margin-left: 10px;" confirm-button-text='确定' cancel-button-text='不用了'
						icon="el-icon-info" icon-color="red" title="确定要删除吗？" @confirm="handleDelete(scope.row.id)">
						<el-button size="mini" type="danger" slot="reference">删除</el-button>
						<!-- <el-button slot="reference">删除</el-button> -->
					</el-popconfirm>
					<!-- <el-button size="mini" type="danger" @click="handleDelete(scope.row.id)">删除</el-button> -->
				</template>
			</el-table-column>
		</el-table>
		<!-- 完整功能分页 -->
		<el-pagination :current-page="currentPage" :page-sizes="[15, 20, 30, 50]" :page-size="queryParams.pageSize"
			layout="->, sizes, prev, pager, next, jumper" :total="total" @size-change="handleSizeChange"
			@current-change="handleCurrentChange" />
		<!-- 弹窗-添加/修改用户 -->
		<el-dialog :title="flag == true ? '添加话题': '修改话题' " :center="true" :visible.sync="dialogFormVisible" width="50%">
			<el-form ref="form" :model="form" :rules="formRules">
				<!-- 分类标题 -->
				<el-form-item label="标题:" :label-width="formLabelWidth" prop="gambit_title">
					<el-input v-model="form.gambit_title" autocomplete="off" placeholder="请输入标题" />
				</el-form-item>

				<div class="fljus">
					<el-form-item label="类型:" :label-width="formLabelWidth" prop="gambit_questionCategory">
						<el-select v-model="form.gambit_questionCategory" clearable>
							<el-option v-for="item in gambit_questionCategorydata" :key="item.id" :label="item.name"
								:value="item.value" />
						</el-select>
					</el-form-item>

					<el-form-item label="答案类型:" :label-width="formLabelWidth" prop="gambit_answerType">
						<el-select v-model="form.gambit_answerType" clearable>
							<el-option v-for="item in gambit_answerTypeselect" :key="item.id" :label="item.name"
								:value="item.value" />
						</el-select>
					</el-form-item>
				</div>

				<div class="fljus">
					<el-form-item label="题目类型:" :label-width="formLabelWidth" prop="gambit_questionType">
						<el-select v-model="form.gambit_questionType" clearable>
							<el-option v-for="item in gambit_questionTypeselect" :key="item.id" :label="item.name"
								:value="item.value" />
						</el-select>
					</el-form-item>
					<el-form-item label="话题状态:" :label-width="formLabelWidth" prop="gambit_questionType">
						<el-select v-model="form.gambit_start" clearable>
							<el-option v-for="item in gambit_statusselect" :key="item.id" :label="item.name"
								:value="item.value" />
						</el-select>
					</el-form-item>
				</div>


				<div class="fljus">
					<!-- 指 0 免费 1 广告 2=插屏 3=金钱钱 -->
					<el-form-item label="运营方式:" :label-width="formLabelWidth" prop="gambit_free_type">
						<el-select v-model="form.gambit_free_type" clearable>
							<el-option v-for="item in gambit_free_typeselect" :key="item.id" :label="item.name"
								:value="item.value" />
						</el-select>
					</el-form-item>
					<!-- <el-form-item label="广告id:" :label-width="formLabelWidth" prop="gambit_price"
						v-if="form.gambit_free_type == 1">
						<el-input v-model="form.gambit_price" autocomplete="off" placeholder="广告id" />
					</el-form-item> -->
					<el-form-item label="测试金额:" :label-width="formLabelWidth" prop="gambit_advertising_id"
						v-if="form.gambit_free_type == 3">
						<el-input-number v-model="form.gambit_advertising_id" :precision="2" :step="0.01"
							:max="10"></el-input-number>
					</el-form-item>
				</div>

				<!-- 指 0 免费 1 广告 2是具体钱 -->
				<el-form-item label="题目:" :label-width="formLabelWidth" prop="gambit_free_type">
					<el-select v-model="form.gambit_topic" multiple filterable remote
						placeholder="请输入题目名字" :remote-method="remoteMethod" @blur="gettopicdiction()" :loading="loading_yy_select"
						style="width: 100%;">
						<el-option v-for="item in options_topic" :key="item.topic_identi" :label="item.topic_title"
							:value="item.topic_identi" />
					</el-select>
				</el-form-item>

				<el-form-item label="描述:" :label-width="formLabelWidth" prop="gambit_subText">
					<el-input type="textarea" v-model="form.gambit_subText" autocomplete="off" placeholder="请输入描述" />
				</el-form-item>

				<!-- <el-form-item label="图标显示:" :label-width="formLabelWidth" prop="classify_cover">
					<el-image style="width: 140px; height: 140px"
						:src="form.classify_cover?form.classify_cover : $store.state.iphost+'/img/all/add.png'"
						@click="ceshifilekong" fit="contain"></el-image>
				</el-form-item> -->


			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button type="danger" @click="cancel">取消</el-button>
				<el-button type="primary" @click="confirm">确定</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	// 系统用户相关的
	const {
		lists_gambit,
		add_up_gambit,
		dels_gambit,
	} = require("@/api/interest_gambit");
	const {
		lists_topic,
	} = require("@/api/interest_topic");
	import {
		gambit_questionCategorydata,
		gambit_answerTypeselect,
		gambit_questionTypeselect,
		gambit_statusselect,
		gambit_free_typeselect
	} from '../../util/qw_enumer.js'
	import fileinterspace from '@/components/fileinterspace/index.vue'
	export default {
		components: {
			fileinterspace
		},
		data() {
			return {
				loading_yy_select: false,
				gambit_questionCategorydata,
				gambit_answerTypeselect,
				gambit_questionTypeselect,
				gambit_statusselect,
				gambit_free_typeselect,
				gambit_free_typevalue: ['1', '2', '4'],
				options_topic: [],
				selectfileAr: [],
				showfilevisible: false,
				ishowbutton: false,
				// 进来加载表单的样式
				loading: true,
				// 顶部表单需要的数据
				queryParams: {
					// 页码
					pageNo: 1,
					// 每页显示多少条
					pageSize: 10,
				},
				// 选择器 数据
				options: [{
					id: 0,
					value: 0,
					name: '下架'
				}, {
					id: 1,
					value: 1,
					name: '上架'
				}],
				// 日期范围
				dateRange: [],
				// 表格数据
				tableData: [],
				// form 表单的lable属性 宽度
				formLabelWidth: '110px',
				// 数据总数量
				total: null,
				// 分页组件数据
				currentPage: 1,
				// 角色列表
				roleList: [],
				// 表单参数
				form: {},
				// 表单校验
				formRules: {
					classify_cover: [{
						required: true,
						message: '没有封面~~',
						trigger: 'blur'
					}],
					classify_remark: [{
						required: true,
						message: '请输入描述',
						trigger: 'blur'
					}],
					classify_title: [{
						required: true,
						message: '标题不能为空',
						trigger: 'blur'
					}]
				},
				// 单选框 数据
				radio: null,
				// 是否显示 添加/修改弹窗
				dialogFormVisible: false,
				// 判断是新增 还是 修改
				flag: false,
				menu_access: []
			}
		},
		watch: {
			dateRange: function() {
				this.queryParams.start_time = this.dateRange && this.dateRange[0] || undefined
				this.queryParams.end_time = this.dateRange && this.dateRange[1] || undefined
				this.handleQuery()
			},
			'queryParams.title': function() {
				if (this.timeout) {
					clearTimeout(this.timeout)
				}
				this.timeout = setTimeout(() => {
					this.getRoleLists()
				}, 700);
			},
			'queryParams.start': function() {
				this.getRoleLists()
			},
		},
		async created() {
			// 调用 封装好的 角色列表接口函数
			this.getRoleLists();
			this.ishowbutton = this.$is_button_power("childvideo")
		},
		methods: {

			// 获取对应的题目的字典信息 | 每次获取100且为最新添加的
			async gettopicdiction(topic_title) {
				this.loading_yy_select = true;
				let lists_topicres = await lists_topic({
					pageSize: 99999,
					topic_title
				})
				this.options_topic = lists_topicres.data.rows;
				this.loading_yy_select = false;
			},

			async remoteMethod(query) {
				if(query != ''){
					this. gettopicdiction(query);
				}

			},

			// 测试的类型
			gambit_questionCategoryfun(num) {
				return this.gambit_questionCategorydata.filter(obj => obj.value === num)[0];
			},


			// 测试文件空间的
			ceshifilekong() {
				this.showfilevisible = !this.showfilevisible
			},
			selectfileArrfun(item) {
				this.form.classify_cover = item.selectlistitem[0].full_path;
			},

			tochild(item) {
				this.$router.push(`/slidevideo/childvideo/${item.id}`);
			},
			//导出的操作
			// async countDowndata(){
			//   window.location.href = `${requrl}/api/backend.Log/export?operator=${this.queryParams.operator}&&
			//   start_time=${this.queryParams.start_time}&&end_time=${this.queryParams.end_time}&&module=${this.queryParams.module}`
			// }
			// 执行删除信息
			async deletedata() {
				this.$confirm('此操作将永久删除记录, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(async () => {
					if (!this.Arraydelete) {
						this.$message.error("请选择你要删除的信息~~")
						return;
					}
					this.handleDelete(this.Arraydelete);
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消删除'
					});
				});
			},

			// 获取删除的选项的信息
			handleSelectionChange(val) {
				let deleteArrayS = ""
				val.forEach((item, index) => {
					if (deleteArrayS == "") { //说明是空的
						deleteArrayS += item.id
					} else {
						deleteArrayS += "," + item.id
					}
				})
				this.Arraydelete = deleteArrayS
			},
			// 点击刷新按钮
			async handshua() {
				await this.getRoleLists();
				this.$message.success('刷新成功~')
			},

			// 封装列表接口函数
			async getRoleLists() {
				this.loading = true;
				console.log(this.queryParams)
				const {
					data
				} = await lists_gambit(this.queryParams)
				this.tableData = data.rows
				this.total = data.count;
				this.loading = false;
			},
			// 封装 根据表id查询角色
			async get_role(obj) {
				const {
					data
				} = await get_role(obj)
				this.form = {
					...this.form,
					...data
				}
			},

			// 封装 添加修改角色接口函数
			async add_or_edit_role(obj) {
				let usersaveres = await add_up_gambit(obj);
				usersaveres.code == 1 ? this.$message({
					type: 'success',
					message: this.flag ? '新增成功~~' : '修改成功~~'
				}) : ''
			},
			// 封装 删除 接口函数
			async dels_gambit(data) {
				// 说明是当页最后一个 且不是第一页
				if (this.tableData.length == 1 && this.queryParams.pageNo > 1) {
					this.queryParams.pageNo--
				}
				let dels_gambit_res = await dels_gambit(data);
				dels_gambit_res.code == 1 ? this.$message({
					type: 'success',
					message: "删除成功~~"
				}) : ''
				// 调用 封装好的 角色列表接口函数
				this.getRoleLists()

				// 在删除表单最后一条数据时,自动返回上一页,但实际情况是还是删除前的页数,数据为空
				/**
				 *    this.total： 总数据条数
				 *    this.queryParams.pageSize： 每页多少条数据
				 *    this.queryParams.pageNo： 当前页码(当前是哪一页)
				 */
				const totalPage = Math.ceil((this.total - 1) / (this.queryParams.pageSize ? this.queryParams.pageSize :
					10))
				this.queryParams.pageNo = this.queryParams.pageNo > totalPage ? totalPage : this.queryParams.pageNo
				this.queryParams.pageNo = this.queryParams.pageNo < 1 ? 1 : this.queryParams.pageNo
			},

			// 顶部 新增按钮  点击触发的函数
			async handleAdd() {
				// 重置 弹窗里面的 表单数据 --开始--
				this.form = {}
				// 菜单列表
				this.menuOptions = []
				// 重置 弹窗里面的 表单数据 --结束--
				// 判断是新增
				this.flag = true
				// 显示或者隐藏 添加/修改用户 弹窗
				this.dialogFormVisible = !this.dialogFormVisible
			},

			// 点击操作里 修改按钮 触发的函数
			async handleEdit(row) {
				// 显示或者隐藏 添加/修改用户 弹窗
				this.dialogFormVisible = !this.dialogFormVisible;
				this.form = row;
			},

			// 点击操作里 启动/冻结 按钮 触发的函数
			async startUsingOrFreeze(obj) {
				let status_set = obj.identitystart == 1 ? 2 : 1;
				let statusres = await srole_status({
					id: obj.id,
					identitystart: status_set
				})
				statusres.code == 1 ? this.$message({
					type: 'success',
					message: status_set == 1 ? '解冻成功' : '冻结成功'
				}) : ''
				this.getRoleLists()
			},
			// 点击修改
			async handleMember(form) {
				let formjs = JSON.parse(JSON.stringify(form));
				// 增加对应的获取所有的题目
				formjs.gambit_topic = JSON.parse(formjs.gambit_topic)
				this.form = formjs;

				this.gettopicdiction()

				// 判断是新增
				this.flag = false;
				// 显示或者隐藏 添加/修改用户 弹窗
				this.dialogFormVisible = !this.dialogFormVisible
			},

			// 点击操作里 删除按钮 触发的函数
			handleDelete(id) {
				// 调用 封装好的 删除角色 接口函数
				this.dels_gambit({
					ids: id
				})
			},

			// 点击查询按钮 触发的函数
			handleQuery() {
				this.getRoleLists()
			},

			// 点击重置按钮 触发的函数
			resetQuery() {
				// 重置数据
				this.queryParams = {};
				this.dateRange = [];
				// 如果重置效果也需要数据回到最初状态，则直接调用 角色列表接口函数
				this.getRoleLists()
			},

			// 弹窗里面的取消按钮
			cancel() {
				this.dialogFormVisible = !this.dialogFormVisible
				this.flag = false
				// 重置数据
				this.form = {}
				this.$nextTick(() => {
					// 移除表单校验效果
					this.$refs.form.clearValidate()
				})
			},

			// 弹窗里面的确认按钮
			async confirm() {
				console.log(this.form)
				// 表单校验
				await this.$refs.form.validate();
				let nowform = JSON.parse(JSON.stringify(this.form));

				nowform.gambit_topic = JSON.stringify(nowform.gambit_topic)

				// // 调用 添加/修改 角色接口函数
				await this.add_or_edit_role(nowform)
				// // 移除表单校验效果
				this.$refs.form.clearValidate()
				// 刷新页面
				await this.getRoleLists();
				this.dialogFormVisible = !this.dialogFormVisible;
				this.flag = false
				// 重置数据
				this.form = {}
			},

			// 点击切换 每页几条数据时 触发的函数
			handleSizeChange(val) {
				// 把切换的每页页数 重新赋值给 this.queryParams.pageSize
				this.queryParams.pageSize = val
				// 调用 封装好的 角色列表接口函数
				this.getRoleLists()
			},
			// 更改 前进几页 数据时 触发的函数
			handleCurrentChange(val) {
				// 把更改的页码 重新赋值给 this.queryParams.pageNo
				this.queryParams.pageNo = val
				// 调用 封装好的 角色列表接口函数
				this.getRoleLists()
			},

			// element ui 表格 格式化 函数
			formatStateCompany(row, column, cellValue) {
				if (cellValue === 1) {
					return '启用'
				} else if (cellValue === 2) {
					return '冻结'
				}
			}
		}
	}
</script>

<style lang="less" scoped>
	.wrap {
		margin: 20px;

		// 分页组件 距离上边的距离
		.el-pagination {
			margin-top: 20px;
		}
	}

	// 表头最外边框
	::v-deep .el-table__header {
		margin-top: 20px;
		border: 1px solid #ebeef5;
	}

	.dialog-footer {
		display: flex;
		justify-content: flex-end;
	}

	.fljus {
		display: flex;
		justify-content: space-between;
	}
</style>