import request from '@/network/request.js'
/**
 * 获取测试分类列表
 */
export function lists_gambit(data) {
	return request({
		url: '/admin/interest_gambit/lists_gambit',
		method: 'post',
		data,
	})
}

/**
 * 增加|修改话题
 */
export function add_up_gambit(data) {
	return request({
		url: '/admin/interest_gambit/add_up_gambit',
		method: 'post',
		data,
	})
}

/**
 * 删除|批量类型分类
 */
export function dels_gambit(data) {
	return request({
		url: '/admin/interest_gambit/dels_gambit',
		method: 'post',
		data,
	})
}



